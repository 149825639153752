import React from "react";

import {PortfolioContent} from "components/Portfolio";

interface IProps {}

export const Portfolio = (
  {}:IProps) => {
  return (
    <PortfolioContent/>
  );
};
