import React, {ReactNode} from "react";

interface IProps {
  children?: ReactNode;
  style?: "default" | "border"
}

export const PageTitle = (
  {
    children,
    style = "default"
  }:IProps) => {
  return (
    <div className={`relative flex w-full items-center gap-x-5 justify-between pt-[72px] pb-10 ${style === "border" && "mb-10 border-b border-solid pb-5 border-gray-200"}`}>
      <div className={"relative block font-bold text-[32px] text-black max480:text-2xl"}>
        {children}
      </div>
    </div>
  );
};
