import React from "react";

import {usePortfolio} from "providers/PortfolioPage";
import {Container} from "components/Container";
import {PageTitle} from "components/PageTitle";

export const PortfolioContentPage = () => {
  const {isObject} = usePortfolio();
  return (
    <Container>
      <PageTitle
        children={isObject?.title}
      />
      <div className={"relative flex w-full gap-11 max768:flex-col"}>
        <div className={"relative block flex-1 max-w-[642px] max768:flex-none max768:w-full max768:max-w-full"}>
          <div
            className={"relative block w-full h-0 pb-[100%] bg-no-repeat bg-center bg-cover rounded-3xl"}
            style={{backgroundImage: `url(${isObject?.image})`}}
          />
        </div>
        <div className={"relative block flex-1 max1024:flex-[2] max768:flex-none max768:w-full"}>
          {isObject?.innerTitle && (
            <div className={"relative block text-lg font-bold text-black mb-7"}>
              {isObject?.innerTitle}
            </div>
          )}
          <div className={"relative block *in:text-sm *in:leading-7 *in:text-black ain:text-blue_click hover:ain:underline **:mt-4"}>
            {isObject?.text}
          </div>
        </div>
      </div>
    </Container>
  );
};
