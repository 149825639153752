import React, {ReactNode} from "react";
import {Link} from "react-router-dom";

interface IProps {
  img?: ReactNode;
  title?: string;
  children?: ReactNode;
  innerTitle?: ReactNode;
  link?: string;
}

export const PortfolioItem = (
  {
    img,
    title,
    children,
    innerTitle,
    link
  }:IProps) => {
  return (
    <div className={"relative flex w-full p-6 border border-solid border-text_hover gap-x-7 rounded-3xl max640:flex-col"}>
      <div
        style={{backgroundImage: `url(${img})`}}
        className={"relative block flex-none w-full max-w-[292px] h-[186px] rounded-2xl bg-no-repeat bg-cover bg-center max768:max-w-[200px] max640:max-w-full"}
      />
      <div className={"relative block flex-1 pt-3 min-w-0"}>
        <div className={"relative block text-black text-2xl font-bold mb-5"}>
          {title}
        </div>
        <div className={"relative block *in:text-innerpage_text_style *in:text-sm *in:!leading-6 ain:!text-blue_click ain:cursor-pointer hover:ain:underline"}>
          <span className={"relative line-clamp-4"}>
            {innerTitle && <p>{innerTitle}</p>}
            {children}
          </span>
          {link && <Link to={link} className={"relative flex max640:mt-4 w-max"}>Read More</Link>}
        </div>
      </div>
    </div>
  );
};
