import React, {useState} from "react";

import {Container} from "components/Container";
import {PageTitle} from "components/PageTitle";
import {allNewsPortfolio} from "components/Portfolio/Item/allNews";
import {PortfolioItem} from "components/Portfolio/Item";
import {Button} from "components/Button";

export const PortfolioContent = () => {
  const num = 5;
  const [isVisible, setVisible] = useState<number>(allNewsPortfolio.length > num ? allNewsPortfolio.length  - num : 0);
  const onSubmit = () => {
    setVisible(isVisible-num >= 0 ? isVisible-num : 0);
  };
  return (
    <Container>
      <PageTitle>
        Charity portfolio HelpUA
      </PageTitle>
      <div className={"relative flex flex-col gap-7 w-full"}>
        {allNewsPortfolio && allNewsPortfolio.slice(0).reverse().slice(isVisible, 9999).reverse().map((item, index) => {
          return (
            <PortfolioItem
              key={index}
              img={item?.cover || item?.image}
              title={item?.title}
              innerTitle={item?.innerTitle}
              link={`/portfolio/${item?.id}`}
            >
              {item?.text}
            </PortfolioItem>
          );
        })}
      </div>
      {isVisible >= 1 && (
        <Button
          onClick={onSubmit}
          children={"Load more"}
          className={"mt-10 h-12 !rounded-2xl !bg-transparent border border-solid !border-blue_static *in:!text-black *in:!font-normal *in:!tr-all hover:!bg-blue_static *in:hover:!text-white"}
        />
      )}
    </Container>
  );
};
