import React from "react";

import {PortfolioProvider} from "providers/PortfolioPage";
import {PortfolioContentPage} from "components/Portfolio/page";

export const PortfolioPage = () => {
  return (
    <PortfolioProvider>
      <PortfolioContentPage/>
    </PortfolioProvider>
  );
};
